var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"py-4 px-4"},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-dialog',{attrs:{"persistent":"","scrollable":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"permissions",rawName:"v-permissions",value:(['CREATE']),expression:"['CREATE']"}],attrs:{"color":"#ff5a5f","dark":""},on:{"click":function($event){return _vm.createCategory()}}},'v-btn',attrs,false),on),[_vm._v(" Agregar Categoria ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitData($event)}}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.dialogTitle))])]),_c('v-divider'),_c('v-card-text',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('validation-provider',{attrs:{"name":"CategoryName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Nombre","required":"","placeholder":"Nombre","outlined":""},model:{value:(_vm.postDataProductCategory.typeProd),callback:function ($$v) {_vm.$set(_vm.postDataProductCategory, "typeProd", $$v)},expression:"postDataProductCategory.typeProd"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Deciption"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"error-messages":errors,"placeholder":"Descripción","outlined":"","label":"Descripción"},model:{value:(_vm.postDataProductCategory.description),callback:function ($$v) {_vm.$set(_vm.postDataProductCategory, "description", $$v)},expression:"postDataProductCategory.description"}})]}}],null,true)})],1)],1)],1)]}}])})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cerrar ")]),_c('v-btn',{attrs:{"color":"green darken-1","text":"","type":"submit","loading":_vm.loading}},[_vm._v(" Guardar ")])],1)],1)],1)])],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-text-field',{staticStyle:{"max-width":"350px","width":"100%"},attrs:{"solo":"","label":"Buscar categorias","clearable":"","light":"","dense":"","hide-details":""},on:{"change":function($event){return _vm.changeInput()}},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.parseSearchString($event)}},model:{value:(_vm.searchCategory),callback:function ($$v) {_vm.searchCategory=$$v},expression:"searchCategory"}}),_c('v-btn',{attrs:{"dark":"","color":"#ff5a5f"},on:{"click":function($event){return _vm.parseSearchString()}}},[_c('v-icon',[_vm._v("fas fa-search")])],1)],1)])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-card',{staticClass:"py-2 mb-4"},[_c('v-data-table',{staticClass:"mx-2",attrs:{"items":_vm.productCategories.productCategories,"headers":_vm.headers,"loading":_vm.loadingTable,"hide-default-header":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var headers = ref.props.headers;
return [_c('thead',[_c('tr',_vm._l((headers),function(item,id){return _c('th',{key:id,staticClass:"text-start",attrs:{"role":"columnheader","scope":"col","aria-label":"Imagen"}},[_c('span',[_vm._v(_vm._s(item.text))])])}),0)])]}},{key:"item.typeProd",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{directives:[{name:"permissions",rawName:"v-permissions",value:(['EDIT']),expression:"['EDIT']"}],attrs:{"text":"","color":"#ff5a5f"},on:{"click":function($event){return _vm.editCategory(item.zbProductCategoryId)}}},[_vm._v(" "+_vm._s(item.typeProd)+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{directives:[{name:"permissions",rawName:"v-permissions",value:(['DELETE']),expression:"['DELETE']"}],staticClass:"mx-2",attrs:{"fab":"","dark":"","x-small":"","color":"error"},on:{"click":function($event){return _vm.destroyCategory(item.zbProductCategoryId)}}},[_c('v-icon',{attrs:{"dark":"","x-small":""}},[_vm._v("fas fa-trash")])],1)]}},{key:"no-data",fn:function(){return [_vm._v(" No hay entradas disponibles ")]},proxy:true}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }