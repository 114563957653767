import { ServiceFactory } from '@/services/ServiceFactory'
const _productCategoriesService = ServiceFactory.get('ProductCategoriesService')
// const _barMembersService = ServiceFactory.get('BarMembersService')
export default {
  data () {
    return {
      loadingTable: true,
      loading: false,
      headers: [
        {
          text: 'Nombre',
          value: 'typeProd',
          sortable: false
        },
        {
          text: 'Descripcion',
          value: 'description',
          sortable: false
        },
        {
          text: 'Editar/Borrar',
          value: 'action',
          sortable: false
        }
      ],
      page: 1,
      dialog: false,
      productCategories: [],
      postDataProductCategory: {
        zbBarId: '',
        typeProd: '',
        description: '',
        category: ''
      },
      submitType: '',
      searchCategory: '',
      dialogTitle: 'Agregar categoria'
    }
  },
  mounted () {
    this.initialize()
  },
  methods: {
    async initialize () {
      if (this.$route.params.id) {
        // console.log(this.$route.params.id, 'Bar products category')
        try {
          // this.barData = await _barsService.show(this.$route.params.id)
          this.productCategories = await _productCategoriesService.index(this.$route.params.id)
          this.loadingTable = false
          // console.log(this.productCategories)
        } catch (error) {
          // console.info(error)
        }
      } else {
        // console.log('sin parametros')
        // this.loadingForm = false
        this.$router.push({ name: 'companies' })
      }
    },
    async submitData () {
      // console.log(this.postDataProductCategory)
      this.loading = true
      if (this.$route.params.id) {
        // console.log(this.submitType)
        switch (this.submitType) {
          case 'CREATE':
            try {
              this.postDataProductCategory.zbBarId = this.$route.params.id
              // this.postDataProductCategory.price = parseInt(this.postDataProductCategory.price)
              this.postProduct = await _productCategoriesService.create(this.postDataProductCategory)
              this.loadingTable = false
              this.loading = false
              this.dialog = false
              // console.log(this.postProduct)
              this.$notify({
                type: 'success',
                text: 'Datos registrados correctamente'
              })
              this.initialize()
            } catch (error) {
              // console.info(error)
            }
            break
          case 'EDIT':
            try {
              // this.postDataProductCategory.zbBarId = this.$route.params.id
              const editResponse = await _productCategoriesService.edit(this.postDataProductCategory.zbProductCategoryId, this.postDataProductCategory)
              this.loadingTable = false
              this.loading = false
              this.dialog = false
              // console.log(editResponse)
              this.$notify({
                type: 'success',
                text: 'Datos registrados correctamente'
              })
              this.initialize()
            } catch (error) {
              // console.info(error)
            }
            break
          default:
            break
        }
      } else {
        // console.log('Sin datos del negocio')
        // this.loadingForm = false
      }
    },
    async createCategory () {
      this.submitType = 'CREATE'
      this.dialogTitle = 'Agregar categoria'
      try {
        this.postDataProductCategory.typeProd = ''
        this.postDataProductCategory.description = ''
        this.postDataProductCategory.category = ''
        // console.log(this.submitType)
      } catch (error) {
        // console.log(error)
        this.$notify({
          title: 'Error:',
          type: 'error',
          text: error.msg
        })
      }
    },
    async editCategory (id) {
      this.submitType = 'EDIT'
      this.dialogTitle = 'Editar categoria'
      this.dialog = true
      // console.log(id, this.submitType)
      try {
        const categoryData = await _productCategoriesService.show(id)
        this.postDataProductCategory.typeProd = categoryData.productCategory.typeProd
        this.postDataProductCategory.description = categoryData.productCategory.description
        this.postDataProductCategory.category = categoryData.productCategory.category
        this.postDataProductCategory.zbProductCategoryId = id
        // console.log(this.postDataProductCategory, this.submitType)
      } catch (error) {
        // console.log(error)
        this.$notify({
          title: 'Error:',
          type: 'error',
          text: error.msg
        })
      }
    },
    destroyCategory (id) {
      // console.log(id)
      this.$swal({
        title: '¿Está seguro de eliminar la categoria?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, eliminar',
        cancelButtonText: 'Cancelar'
      }).then(async (result) => {
        // console.log(result)
        if (result.isConfirmed) {
          try {
            // console.log('deletecat')
            await _productCategoriesService.destroy(id)
            this.$swal(
              'Categoría eliminada',
              'Se eliminó correctamente',
              'success'
            ).then(async (result) => {
              this.initialize()
            })
          } catch (error) {
            this.$notify({
              title: 'Error:',
              type: 'error',
              text: error.message + ', no se pudo eliminar.'
            })
          }
        }
      })
    },
    async parseSearchString () {
      try {
        // console.log(this.searchCategory, this.$route.params.id)
        this.productCategories = await _productCategoriesService.searchCategory(this.$route.params.id, this.searchCategory)
        // console.log(this.productCategories)
      } catch (error) {
        // console.log(error)
        this.$swal(
          error.msg
        )
      }
    },
    changeInput () {
      if (!this.searchCategory) {
        this.initialize()
      }
    },
    goNewForm (id, payload = null) {
      // console.log(id)
      // this.$router.push({ name: 'member-form', params: { type: 'addMember', id } })
    }
  }
}
