import { ServiceFactory } from '@/services/ServiceFactory'
const _productsService = ServiceFactory.get('ProductsService')
const _productCategoriesService = ServiceFactory.get('ProductCategoriesService')
export default {
  data () {
    return {
      loadingTable: true,
      loading: false,
      loadinDelete: false,
      headers: [
        {
          text: 'Imagen',
          value: 'image',
          sortable: false
        },
        {
          text: 'Nombre',
          value: 'name',
          sortable: false
        },
        {
          text: 'Precio',
          value: 'price',
          sortable: false
        },
        {
          text: 'Tipo de producto',
          value: 'typeProd',
          sortable: false
        },
        {
          text: 'Descripcion',
          value: 'description',
          sortable: false
        },
        {
          text: 'Disponible para descuento',
          value: 'discount',
          sortable: false
        },
        {
          text: 'Editar/Borrar',
          value: 'options',
          sortable: false
        }
      ],
      page: 1,
      dialog: false,
      products: [],
      categories: [],
      postDataProduct: {
        name: '',
        zbBarId: '',
        price: 0,
        // numberTickets: 0,
        typeProd: '',
        description: '',
        category: '',
        image: null
      },
      submitType: '',
      searchProduct: '',
      colorDiscount: '',
      dialogTitle: 'Agregar producto',
      imgBanner: {
        image: ''
      },
      imageBanner: null
    }
  },
  mounted () {
    this.initialize()
  },
  created () {
    this.$watch(
      () => this.$route.params,
      (toParams, previousParams) => {
        console.log(toParams, previousParams)
        return this.initialize()
        // react to route changes...
      }
    )
  },
  watch: {
    '$route' (to, from) {
      console.log(to, from)
      if (from.params.id !== to.params.id) {
        return this.initialize()
      }
    }
  },
  methods: {
    async initialize () {
      if (this.$route.params.id) {
        // console.log(this.$route.params.id, 'Bar products')
        try {
          // this.barData = await _barsService.show(this.$route.params.id)
          this.products = await _productsService.index(this.$route.params.id)
          this.categories = await _productCategoriesService.index(this.$route.params.id)
          this.loadingTable = false
          console.log(this.products)
        } catch (error) {
          // console.info(error)
        }
      } else {
        // console.log('sin parametros')
        // this.loadingForm = false
        this.$router.push({ name: 'companies' })
      }
    },
    async submitData () {
      // console.log(this.postDataProduct)
      this.loading = true
      if (this.$route.params.id) {
        // console.log(this.submitType)
        switch (this.submitType) {
          case 'CREATE':
            try {
              this.postDataProduct.zbBarId = this.$route.params.id
              this.postDataProduct.price = parseInt(this.postDataProduct.price)
              // console.log(this.postDataProduct)
              this.postProduct = await _productsService.create(this.postDataProduct)
              this.loadingTable = false
              // console.log(this.postProduct)
              this.loading = false
              this.dialog = false
              this.$notify({
                type: 'success',
                text: 'Datos registrados correctamente'
              })
              this.initialize()
            } catch (error) {
              this.loading = false
              this.$notify({
                type: 'error',
                text: 'Ocurrió un error al registrar los datos, intente nuevamente'
              })
            }
            break
          case 'EDIT':
            try {
              this.postDataProduct.zbBarId = this.$route.params.id
              this.postDataProduct.price = parseInt(this.postDataProduct.price)
              // console.log(this.postDataProduct)
              const editResponse = await _productsService.edit(this.postDataProduct.zbProductId, this.postDataProduct)
              this.loadingTable = false
              // console.log(editResponse)
              this.loading = false
              this.dialog = false
              this.$notify({
                type: 'success',
                text: 'Datos registrados correctamente'
              })
              this.initialize()
            } catch (error) {
              this.loading = false
              this.$notify({
                type: 'error',
                text: 'Ocurrió un error al registrar los datos, intente nuevamente'
              })
            }
            break
          default:
            break
        }
      } else {
        // console.log('Sin datos del negocio')
        // this.loadingForm = false
      }
    },
    async createProduct () {
      this.submitType = 'CREATE'
      this.dialogTitle = 'Agregar producto'
      try {
        this.postDataProduct.name = ''
        this.postDataProduct.price = 0
        // this.postDataProduct.numberTickets = 0
        this.postDataProduct.typeProd = ''
        this.postDataProduct.description = ''
        this.postDataProduct.category = ''
        this.categories = await _productCategoriesService.index(this.$route.params.id)
        // console.log(this.submitType)
      } catch (error) {
        // console.log(error)
      }
    },
    async editProduct (id) {
      // console.log(id)
      this.submitType = 'EDIT'
      this.dialogTitle = 'Editar producto'
      this.dialog = true
      try {
        this.categories = await _productCategoriesService.index(this.$route.params.id)
        const productData = await _productsService.show(id)
        // console.log(productData)
        this.postDataProduct.name = productData.product.name
        // this.postDataProduct.zbBarId = 'id'
        this.postDataProduct.price = productData.product.price
        this.imgBanner.image = productData.product.image
        // this.postDataProduct.typeProd = productData.product.typeProd
        this.postDataProduct.description = productData.product.description
        this.postDataProduct.category = productData.product.typeProd
        this.postDataProduct.zbProductId = id
        // console.log(this.postDataProduct, this.submitType)
      } catch (error) {
        // console.log(error)
      }
    },
    destroyProduct (id) {
      // console.log(id)
      this.$swal({
        title: '¿Está seguro de eliminar el producto?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, eliminar',
        cancelButtonText: 'Cancelar'
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await _productsService.destroy(id)
            this.$swal(
              'Producto eliminado',
              'Se eliminó correctamente',
              'success'
            ).then(async (result) => {
              this.initialize()
            })
          } catch (error) {
            this.$notify({
              title: 'Error:',
              type: 'error',
              text: error.message + ', no se pudo eliminar.'
            })
          }
        }
      })
    },
    enableDiscount (id) {
      // console.log(id)
      this.$swal({
        title: '¿Está seguro de hab/des el producto para descuento?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí',
        cancelButtonText: 'Cancelar'
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await _productsService.discount(id)
            this.$swal(
              'Producto modificado',
              'correctamente',
              'success'
            ).then(async (result) => {
              this.initialize()
            })
          } catch (error) {
            this.$notify({
              title: 'Error:',
              type: 'error',
              text: error.message + ', no se pudo modificar.'
            })
          }
        }
      })
    },
    async parseSearchString () {
      try {
        // console.log(this.searchProduct, this.$route.params.id)
        this.products = await _productsService.searchProduct(this.$route.params.id, this.searchProduct)
        // console.log(this.products)
        // this.companies = searchResult
      } catch (error) {
        // console.log(error)
        this.$swal(
          error.msg
        )
      }
    },
    changeInput () {
      if (!this.searchProduct) {
        this.initialize()
      }
    },
    goNewForm (id, payload = null) {
      // console.log(id)
      // this.$router.push({ name: 'member-form', params: { type: 'addMember', id } })
    },
    handleImgBannerUpload (e) {
      console.log(e)
      this.postDataProduct.image = e.target.files[0]
      console.log(e.target.files[0])
      this.previewImgBanner(e)
      // console.log(this.image)
    },
    previewImgBanner (event) {
      const input = event.target
      if (input.files) {
        const reader = new FileReader()
        reader.readAsDataURL(input.files[0])
        reader.onload = (e) => {
          let img = new Image()
          // img.onload = () => {
          //   this.image.width = img.width
          //   this.image.height = img.height
          // }
          img.src = e.target.result
          this.imgBanner.image = e.target.result
        }
      }
    },
    async deleteImage () {
      try {
        this.loadinDelete = true
        const resp = await _productsService.deleteImage(this.postDataProduct.zbProductId)
        console.log(resp)
        this.imgBanner = {
          image: ''
        }
        this.loadinDelete = false
        this.$notify({
          type: 'success',
          text: 'Imágen eliminada correctamente.'
        })
      } catch (error) {
        console.log(error)
        this.loadinDelete = false
        this.$notify({
          title: 'Error:',
          type: 'error',
          text: error.message + ', no se pudo eliminar.'
        })
      }
    }
  }
}
