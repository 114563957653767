import ZbBarProductComponent from './tab-bar-product'
import ZbProductCategoryComponent from './tab-product-category'
export default {
  components: {
    ZbBarProductComponent,
    ZbProductCategoryComponent
  },
  data () {
    return {
      tab: null,
      items: [
        'Productos',
        'Categorias'
      ]
    }
  },
  mounted () {
    this.initialize()
  },
  created () {
    this.$watch(
      () => this.$route.params,
      (toParams, previousParams) => {
        console.log(toParams, previousParams)
        return this.initialize()
        // react to route changes...
      }
    )
  },
  watch: {
    '$route' (to, from) {
      console.log(to, from)
      if (from.params.id !== to.params.id) {
        return this.initialize()
      }
    }
  },
  methods: {
    async initialize () {
      // console.log(this.$route)
      if (this.$route.params.id) {
        // console.log(this.$route.params.id)
        // const id = this.$route.params.id
        // this.$router.push({ name: 'booking', params: { id } })
      } else {
        // console.log('<----------no id-------------->')
        this.$router.push({ name: 'companies' })
      }
    },
    close () {
      this.$router.push({ path: 'companies' }).catch(() => {})
    }
  }
}
